import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortalService {
  constructor() { }

  getPortalUrl(location: Location) {
    return location.href.replace(`${location.hash}`, '').replace(/\/$/, '');
  }

}
